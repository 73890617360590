export class StickyBar {
    private static _instance: StickyBar;
    private static _context: HTMLElement = document.querySelector("#sticky-bar");
    private _container: HTMLElement;
    private _countdownContainer: HTMLElement;
    private _countdownDate: number;

    private constructor() {
        this.initialize();
    }

    public initialize() {
        if (!StickyBar._context)
            return;

        this._container = StickyBar._context.querySelector("#main-container");
        this._countdownContainer = this._container.querySelector("#countdown-timer");
        this._countdownDate = new Date(this._countdownContainer.getAttribute("data-countdownDate")).getTime();
        this.updateCountdown();
    }

    // Function to update the countdown every second
    private updateCountdown = (): void => {
        let offset = new Date().getTimezoneOffset();
        const now: number = new Date().getTime();

        // Calculate the remaining time
        const distance: number = this._countdownDate - now + (offset * 60000);

        // Time calculations for days, hours, minutes, and seconds
        const days: number = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours: number = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes: number = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds: number = Math.floor((distance % (1000 * 60)) / 1000);

        const daysElement = this._countdownContainer.querySelector("#days");
        const hoursElement = this._countdownContainer.querySelector("#hours");
        const minutesElement = this._countdownContainer.querySelector("#minutes");
        const secondsElement = this._countdownContainer.querySelector("#seconds");

        if (daysElement && hoursElement && minutesElement && secondsElement) {
            daysElement.innerHTML = `${days}d`;
            hoursElement.innerHTML = `${hours}h`;
            minutesElement.innerHTML = `${minutes}m`;
            secondsElement.innerHTML = `${seconds}s`;
        }

        // This should never happen
        if (distance < 0) {
            clearInterval(this.countdownInterval);
            const countdownElement = document.getElementById("countdown");
            if (countdownElement) {
                countdownElement.style.display = "none";
            }
        }
    };

    // Update the countdown every second
    private countdownInterval = setInterval(this.updateCountdown, 1000);

    public static get Instance() {
        return this._context && (this._instance || (this._instance = new this()));
    }
}

export const stickyBar = StickyBar.Instance;

if (stickyBar) {
    (window as any).stickyBar = stickyBar;
}